import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const auth = localStorage.getItem("access_token"); // determine if authorized, from context or however you're doing it
  const user = JSON.parse(localStorage.getItem("user"));

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth && [0, 2, 3].includes(user.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
