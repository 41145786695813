import { useState } from "react";
import Card from "@mui/material/Card";
import { IconButton, Button, CardActions, Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import AxiosInstance from "../AxiosInstance";
import { toast } from "react-toastify";

const Header = ({ accountBook }) => {
  const [isPinned, setIsPinned] = useState(accountBook.pinned_position !== 0);
  return (
    <Grid container>
      <Grid container sx={{ justifyContent: "flex-end" }}>
        <Grid item>
          <IconButton
            aria-label="star"
            onClick={() => {
              console.log("DUCK");
            }}
          >
            {isPinned ? (
              <StarOutlinedIcon
                onClick={() => {
                  setIsPinned(!isPinned);
                  AxiosInstance.post(
                    `expensetracker/account-books/${accountBook.id}/pin-unpin/`,
                    {},
                    {
                      withCredentials: true,
                    }
                  )
                    .then((resp) => {
                      toast.success("Account book removed from favorites!");
                    })
                    .catch((err) => {
                      toast.error(
                        "Fail to remove account book from favorites!"
                      );
                    });
                }}
                sx={{
                  color: "rgb(241,225,129)",
                }}
              />
            ) : (
              <StarBorderOutlinedIcon
                onClick={() => {
                  setIsPinned(!isPinned);
                  AxiosInstance.post(
                    `expensetracker/account-books/${accountBook.id}/pin-unpin/`,
                    {},
                    {
                      withCredentials: true,
                    }
                  )
                    .then((resp) => {
                      toast.success("Account book added to Favorites!");
                    })
                    .catch((err) => {
                      toast.error("Fail to add account book to Favorites!");
                    });
                }}
                sx={{
                  color: "rgb(241,225,129)",
                }}
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <CardHeader
          title={<Typography variant="h6">{accountBook.title}</Typography>}
        />
      </Grid>
    </Grid>
  );
};

export default function HelpCard({ accountBook }) {
  return (
    <Button
      component={Card}
      to={`/account-detail/${accountBook.id}`}
      variant={"text"}
      disableRipple
      sx={{
        padding: 0,
        color: "white",
        minHeight: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        borderRadius: 4,
        height: "100%",
        backgroundSize: "250%",
        transition: "0.6s",
        backgroundImage:
          "linear-gradient(45deg, rgba(46,60,232,1) 0%, rgba(71,140,232,1) 39%, rgba(95,225,255,1) 100%)",
        "&:hover": {
          backgroundPosition: "right",
        },
        "&:focus": {
          backgroundPosition: "right",
        },
      }}
    >
      <Header accountBook={accountBook} />
      <CardActions
        sx={{
          width: "100%",
        }}
        disableSpacing
      >
        <Grid
          container
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Typography variant="body" sx={{ px: 1 }}>
              Rs. {accountBook.balance}/-
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="secondary"
              component={Link}
              to={`/account-detail/${accountBook.id}`}
              variant="outlined"
              sx={{
                mx: 1,
                color: "white",
                borderColor: "white",
                "&:hover": {
                  backgroundColor: "rgba(46,60,232,1)",
                },
              }}
            >
              view
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
            size="small"
            color="secondary"
            component={Link}
            to={`/edit-account/${accountBook.id}`}
              variant="outlined"
              sx={{
                mx: 1,
                color: "white",
                borderColor: "white",
                "&:hover": {
                  backgroundColor: "rgba(46,60,232,1)",
                },
              }}
            >
              edit
            </Button>
          </Grid> */}
        </Grid>
      </CardActions>
    </Button>
  );
}
