import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
// import ScrollToTop from "../Components/ScrollToTop";
import About from "../pages/About";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import PrivateRoute from "./PrivateRoute";
import Logout from "../pages/Logout";
import AccountBooksList from "../pages/AccountBooksList";
import AccountBookDetail from "../pages/AccountBookDetail";
import EditAccountBook from "../pages/EditAccountBook";
import { ToastContainer, toast } from "react-toastify";
import NOT_FOUND from "../pages/404";
import ActivateAccount from "../pages/ActivateAccount";
import ChangePassword from "../pages/ChangePassword";
import ForgetPassword from "../pages/ForgetPassword";
import SetNewPassword from "../pages/SetNewPassword";
import Profile from "../pages/Profile";
import Box from "@mui/material/Box";

const AppRoutes = ({ isAuthenticated }) => {
  return (
    <Router>
      <ToastContainer
        autoClose={3000}
        position={toast.POSITION.BOTTOM_CENTER}
      />
      <Navbar isAuthenticated={isAuthenticated} />
      <CssBaseline />
      {/* <ScrollToTop /> */}
      <Box sx={{ minHeight: "90vh" }}>
        <Routes>
          {!isAuthenticated && <Route exact path={"/"} element={<Login />} />}
          <Route exact path="/about" element={<About />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/helps" element={<AccountBooksList />} />
          <Route exact path="/activate" element={<ActivateAccount />} />
          <Route element={<PrivateRoute />}>
            <Route exact path="/" element={<AccountBooksList />} />
            <Route
              exact
              path="/account-detail/:account_book_id"
              element={<AccountBookDetail />}
            />
            <Route
              exact
              path="/edit-account/:account_book_id"
              element={<EditAccountBook />}
            />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/profile" element={<Profile />} />
          </Route>

          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/forget" element={<SetNewPassword />} />
          <Route path="*" element={<NOT_FOUND />} />
        </Routes>
      </Box>
      <Footer />
    </Router>
  );
};

export default AppRoutes;
