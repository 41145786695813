import Pagination from "@mui/material/Pagination";
import React from "react";

const CustomPagination = ({
  dataCount,
  rowsPerPage,
  page,
  handleChangePage,
}) => {
  return (
    <div sx={{ display: "flex", right: "0px", justifyContent: "flex-end" }}>
      <Pagination
        color="primary"
        count={Math.ceil(dataCount / rowsPerPage)}
        page={page + 1}
        onChange={(e, num) => handleChangePage(e, num - 1)}
        sx={{ padding: "10px 0px", alignSelf: "center" }}
      />
    </div>
  );
};

export default CustomPagination;
